import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Logo from '../images/top_mainimage.png'

class QAPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="qa">
                        <div className="qatitle">よくある質問</div>
                        <div className="qabox">
                            <div className="question">Q：応募したワールドは内容を更新してもよいのでしょうか？</div>
                            <div className="answer">A：提出締め切り〜審査完了までの間は原則としてワールドの改変および消去をご遠慮いただいています。<br />
                                ただし締め切り日以前および当イベント後の更新は自由です。<br />
                                開催時点における説明文等と共に掲載されるイベントの性質上、ワールドの内容も提出時の状態に維持していただくことをお願いしておりますが、その限りではありません。<br />
                                なお、通常の維持・更新するワールドとは別に、提出用に専用のBlueprintIDを発行して参加していただくという選択肢もありますので、都合に応じてご検討ください。</div>
                        </div>
                        <div className="qabox">
                            <div className="question">Q：XR Creative Award 2020と併催とのことですが、両方に同じ作品を提出できますか？ また、VRAA02に応募すれば自動的にXRCAに応募したことになりますか？</div>
                            <div className="answer">A：VRAA02とXRCA2020双方への同一作品提出は可です。ただし、VRAA02提出でXRCA2020自動応募にはなりません。</div>
                        </div>
                        <div className="qabox">
                            <div className="question">Q：応募は1人1ワールドまでですか？</div>
                            <div className="answer">A：代表者名義で1ワールドまでですが、代表者名義でなければ複数の応募も可能です。たとえば、代表者として1ワールドを提出して、別のワールド制作にチームメンバーとして参加するという形は問題ありません。</div>
                        </div>
                        <div className="qabox">
                            <div className="question">Q：アセットストアやBooth等で購入したデータの使用はできますか？</div>
                            <div className="answer">A：それぞれのデータの規約に違反しない範囲で使用していただければ問題ありません。</div>
                        </div>

                    </div>
                    <div id="form">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScN_Tzxm98n5cbjBGxMhFFRTv3KT8fZ9zeGo1QilYnYFVH_og/viewform?usp=sf_link" target="_blank"><button className="qaform">問い合わせはこちらから</button></a>
                    </div>
                    <div id="wrapper-footer">
                        <Footer timeout={this.state.timeout} />
                    </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA02よくある質問</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default QAPage
